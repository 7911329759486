import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { signIn, providers, useSession } from 'next-auth/client';

import { Button, Flex, Spinner, useToast } from '@chakra-ui/react';
import { KubrickLogoIcon } from '@/components/icons';
import theme from '@/providers/theme';
import { TOAST_DEFAULT_ERROR } from '@/constants/global';
import { APP_TIMER } from '../constants/paths';

const TOAST_LOGIN_ERROR_TITLE =
  'The credentials to sign in to Kubrick are incorrect or not allowed';
const TOAST_LOGIN_ERROR_DESCRIPTION =
  'Please make sure you use a valid Qubika account or contact the Kubrick support team.';

const RootPage = ({ providers: obtainedProviders }) => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [session, loading] = useSession();

  const unregisterServiceWorkers = async () => {
    const registrations = await navigator.serviceWorker.getRegistrations();

    return Promise.all(
      registrations.map(registration => registration.unregister()),
    );
  };

  useEffect(() => {
    const { error } = router.query;

    if (error) {
      toast({
        ...TOAST_DEFAULT_ERROR,
        title: TOAST_LOGIN_ERROR_TITLE,
        description: TOAST_LOGIN_ERROR_DESCRIPTION,
        position: 'top',
      });
    }
  }, [router.query]);

  const handleLogin = async event => {
    await unregisterServiceWorkers();

    event.persist();
    setIsLoading(true);
    if (obtainedProviders && obtainedProviders.auth0) {
      signIn(obtainedProviders.auth0.id);
    }
  };

  useEffect(() => {
    if (!loading && session && session.user) {
      router.push(APP_TIMER);
    }
  }, [session, loading, router]);

  return (
    <Flex
      position="fixed"
      left="0"
      background={theme.gradients.base}
      direction="column"
      justify="center"
      align="center"
      width="100%"
      height="100%"
    >
      <KubrickLogoIcon
        color="white"
        width={[250, 320]}
        height={[45, 60]}
        mb="6"
      />
      {!isLoading && (
        <Button px="6" data-testid="login-button" onClick={handleLogin}>
          Login
        </Button>
      )}
      {isLoading && <Spinner size="xl" color="white" />}
    </Flex>
  );
};

RootPage.propTypes = {
  providers: PropTypes.shape({
    auth0: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

RootPage.getInitialProps = async context => {
  return {
    providers: await providers(context),
  };
};

export default RootPage;
